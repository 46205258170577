<template>
  <div class="container">
    <!-- The Modal -->
    <div id="myModal" class="modal" v-if="showModal">
      <!-- Modal content -->
      <div class="add-award" v-if="addAward">
        <div class="modal-content">
          <span class="close" v-on:click="showModal = !showModal">&times;</span>
          <h1 class="modal-title">New Award</h1>
          <p class="req-warning">
            <span class="req-asterik">*</span> Indicates a required field
          </p>
          <h2 class="modal-subtitle">Award Basics</h2>
          <error
            :errors="errors.award"
            :message="errors.award.name"
            :field="'name'"
          >
            <label class="modal-label"
              >Award Name
              <span class="req-asterik">*</span>
              <tooltip :helpText="'Name of award'"></tooltip>
            </label>
            <input type="text" class="modal-text" v-model="award.name" />
          </error>
          <div class="box-container">
            <div class="box-quarter">
              <error
                :errors="errors.award"
                :message="errors.award.code"
                :field="'code'"
              >
                <label class="modal-label"
                  >Award Code
                  <span class="req-asterik">*</span>
                  <tooltip
                    :helpText="'Alphanumeric award identifier'"
                  ></tooltip>
                </label>
                <input
                  type="text"
                  class="modal-text"
                  placeholder="Alphanumeric Code"
                  v-model="award.code"
                />
              </error>
              <error
                :errors="errors.award"
                :message="errors.award.center"
                :field="'center'"
              >
                <label class="modal-label"
                  >Center
                  <span class="req-asterik">*</span>
                  <tooltip
                    :helpText="'Identifies the center an award falls within'"
                  ></tooltip>
                </label>
                <select v-model="award.center">
                  <option value="BCPI">
                    BCPI
                  </option>
                  <option value="GovEx">
                    GovEx
                  </option>
                </select>
              </error>
            </div>
            <div class="box-quarter">
              <error
                :errors="errors.award"
                :message="errors.award.number"
                :field="'number'"
              >
                <label class="modal-label"
                  >Award Number
                  <span class="req-asterik">*</span>
                  <tooltip :helpText="'Award number in SAP'"></tooltip>
                </label>
                <input
                  type="text"
                  class="modal-text"
                  placeholder="SAP Award Number"
                  v-model="award.award_number"
                />
              </error>
              <label class="modal-label"
                >Funds
                <tooltip :helpText="'Total funds of award'"></tooltip>
              </label>
              <input
                type="number"
                min="1"
                step="any"
                class="modal-text"
                placeholder="100000000"
                v-model="award.total_funds"
              />
            </div>
            <div class="box-quarter">
              <label class="modal-label"
                >Start Date
                <tooltip :helpText="'Award start date'"></tooltip>
              </label>
              <input
                type="date"
                class="modal-date"
                placeholder="mm/dd/yyyy"
                v-model="award.start_date"
              />
              <label class="modal-label label-short"
                >Salesforce ID
                <tooltip :helpText="'ID of award in Salesforce'"></tooltip>
              </label>
              <label class="sub-label">For Cities of Service Projects</label>
              <input
                type="text"
                class="modal-text"
                placeholder="Salesforce Award ID"
                v-model="award.salesforce_id"
              />
            </div>
            <div class="box-quarter">
              <label class="modal-label"
                >End Date
                <tooltip :helpText="'Award end date'"></tooltip>
              </label>
              <input
                type="date"
                class="modal-date"
                placeholder="mm/dd/yyyy"
                v-model="award.planned_end_date"
              />
            </div>
          </div>
          <error
            :errors="errors.award"
            :message="errors.award.stage"
            :field="'stage'"
          >
            <label class="modal-label"
              >Stage
              <span class="req-asterik">*</span>
              <tooltip :helpText="'Stage of award'"></tooltip>
            </label>
            <select v-model="award.stage" class="modal-text">
              <option
                v-for="(stage, choice) in awardStages"
                :value="choice"
                :key="choice"
              >
                {{ stage }}
              </option>
            </select>
          </error>
          <h2 class="modal-subtitle">Associated Organizations</h2>
          <div v-for="(org, i) in award.organizations" :value="i" :key="i">
            <div class="box-three-quarters auto-box">
              <AutocompleteSearch
                :search="org.name"
                @selected="
                  (org.organization_id = $event.id), (org.name = $event.name)
                "
              >
              </AutocompleteSearch>
            </div>
            <div class="box-eigth">
              <select v-model="org.relationship">
                <option value="CLIE">
                  Client
                </option>
                <option value="FUND">
                  Funder
                </option>
                <option value="PTNR">
                  Partner
                </option>
              </select>
            </div>
            <div class="box-eigth remove-frame">
              <button
                class="remove-button"
                @click="award.organizations.splice(i, 1)"
              >
                <img class="remove-icon" src="../assets/icons/remove.svg" />
              </button>
            </div>
          </div>
          <button class="add-button" @click="award.organizations.push({})">
            Add Organization +
          </button>
          <!-- <h2 class="modal-subtitle">Associated Programs</h2>
          <div style="display:block;" v-for="(program, i) in award.programs" :value="program.program_id" :key="program.program_id">
          <select v-model="program.program_id" class="modal-text" onchange="(programSelected=this.value)" placeholder="">
            <option v-for="program in programs" :value="program.id" :key="program.id">
              {{ program.name }}
            </option>
          </select>
          <button class="remove-button" @click="award.programs.splice(i, 1)">-</button>
          </div>
          <button class="add-button" @click="award.programs.push({'program_id':programSelected})">Add Program +</button> -->
          <div class="modal-button-box">
            <button class="button submit-modal" @click="createAward">
              Add Award
            </button>
          </div>
        </div>
      </div>
      <div class="add-program" v-if="addProgram">
        <div class="modal-content">
          <span class="close" v-on:click="showModal = !showModal">&times;</span>
          <h1 class="modal-title">New Program</h1>
          <p class="req-warning">
            <span class="req-asterik">*</span> Indicates a required field
          </p>
          <h2 class="modal-subtitle">Program Basics</h2>
          <error
            :errors="errors.program"
            :message="errors.program.name"
            :field="'name'"
          >
            <label class="modal-label"
              >Program Name
              <span class="req-asterik">*</span>
              <tooltip :helpText="'Name of program'"></tooltip>
            </label>
            <input type="text" class="modal-text" v-model="program.name" />
          </error>
          <error
            :errors="errors.program"
            :message="errors.program.code"
            :field="'code'"
          >
            <label class="modal-label"
              >Program Code
              <span class="req-asterik">*</span>
              <tooltip :helpText="'Alphanumeric program identifier'"></tooltip>
            </label>
            <input
              type="text"
              class="modal-text"
              placeholder="Alphanumeric Code"
              v-model="program.code"
            />
          </error>
          <error
            :errors="errors.program"
            :message="['Award is required']"
            :field="'award'"
          >
            <label class="modal-label"
              >Associated Award <span class="req-asterik">*</span></label
            >
            <select v-model="program.award" class="modal-text">
              <option
                v-for="award in awards"
                :value="[{ award: award.id }]"
                :key="award.id"
              >
                {{ award.name }}
              </option>
            </select>
          </error>
          <div class="modal-button-box">
            <button class="button submit-modal" @click="createProgram">
              Add Program
            </button>
          </div>
        </div>
      </div>
      <div class="add-project" v-if="addProject">
        <div class="modal-content">
          <span class="close" v-on:click="showModal = !showModal">&times;</span>
          <h1 class="modal-title">New Project</h1>
          <p class="req-warning">
            <span class="req-asterik">*</span> Indicates a required field
          </p>
          <h2 class="modal-subtitle">Project Basics</h2>
          <div class="box-container">
            <div class="box-half">
              <error
                :errors="errors.project"
                :message="errors.project.name"
                :field="'name'"
              >
                <label class="modal-label"
                  >Project Name
                  <span class="req-asterik">*</span>
                  <tooltip :helpText="'Name of project'"></tooltip>
                </label>
                <input type="text" class="modal-text" v-model="project.name" />
              </error>
            </div>
            <div class="box-half">
              <error
                :errors="errors.project"
                :message="errors.project.code"
                :field="'code'"
              >
                <label class="modal-label"
                  >Project Code
                  <span class="req-asterik">*</span>
                  <tooltip
                    :helpText="'Alphanumeric project identifier'"
                  ></tooltip>
                </label>
                <input
                  type="text"
                  class="modal-text"
                  placeholder="Alphanumeric Code"
                  v-model="project.code"
                />
              </error>
            </div>
          </div>
          <div class="box-container">
            <div class="box-quarter">
              <error
                :errors="errors.project"
                :message="errors.project.project_type"
                :field="'project_type'"
              >
                <label class="modal-label"
                  >Project Type
                  <span class="req-asterik">*</span>
                  <tooltip
                    :helpText="
                      'Designates whether project is a Product, Delivery, or Course project'
                    "
                  ></tooltip>
                </label>
                <select v-model="project.project_type">
                  <option value="CRS">
                    Course
                  </option>
                  <option value="DLV">
                    Delivery
                  </option>
                  <option value="PRD">
                    Product
                  </option>
                </select>
              </error>
            </div>
            <div class="box-quarter">
              <error
                :errors="errors.project"
                :message="errors.project.start_date"
                :field="'start_date'"
              >
                <label class="modal-label"
                  >Start Date
                  <span class="req-asterik">*</span>
                  <tooltip :helpText="'Project start date'"></tooltip>
                </label>
                <input
                  type="date"
                  class="modal-date"
                  v-model="project.start_date"
                />
              </error>
            </div>
            <div class="box-quarter">
              <label class="modal-label"
                >Due Date
                <tooltip :helpText="'Project due date'"></tooltip>
              </label>
              <input
                type="date"
                class="modal-date"
                v-model="project.due_date"
              />
            </div>
            <div class="box-quarter">
              <error
                :errors="errors.project"
                :message="errors.project.project_update_frequency"
                :field="'project_update_frequency'"
              >
                <label class="modal-label"
                  >Update Frequency
                  <span class="req-asterik">*</span>
                  <tooltip :helpText="'Frequency of project updates'"></tooltip>
                </label>
                <select v-model="project.project_update_frequency">
                  <option value="WKL">
                    Weekly
                  </option>
                  <option value="MTL">
                    Monthly
                  </option>
                  <option value="QTR">
                    Quarterly
                  </option>
                </select>
              </error>
            </div>
          </div>
          <div class="box-container">
            <div class="box-third">
              <error
                :errors="errors.project"
                :message="errors.project.project_phase"
                :field="'project_phase'"
              >
                <label class="modal-label"
                  >Project Phase
                  <span class="req-asterik">*</span>
                  <tooltip :helpText="'Indicates the project phase'"></tooltip>
                </label>
                <select class="modal-select" v-model="project.project_phase">
                  <option value="PLN">
                    Scoping/Planning
                  </option>
                  <option value="ACT">
                    Active
                  </option>
                  <option value="WRP">
                    Wrap-Up
                  </option>
                  <option value="CLD">
                    Cancelled
                  </option>
                  <option value="CMP">
                    Completed
                  </option>
                </select>
              </error>
            </div>
            <div class="box-third">
              <label class="modal-label label-med"
                >Salesforce ID
                <tooltip :helpText="'ID of project in Salesforce'"></tooltip>
              </label>
              <label class="sub-label">For Cities of Service Projects</label>
              <input
                type="text"
                class="modal-text"
                placeholder="Associated Salesforce ID"
                v-model="project.salesforce_id"
              />
            </div>
            <div class="box-third">
              <label class="modal-label label-med"
                >Salesforce Grant ID
                <tooltip
                  :helpText="'ID of associated grant in Salesforce'"
                ></tooltip>
              </label>
              <label class="sub-label">For Cities of Service Projects</label>
              <input
                type="text"
                class="modal-text"
                placeholder="Associated Salesforce Grant ID"
                v-model="project.salesforce_grant_id"
              />
            </div>
          </div>
          <label class="modal-label"
            >Description
            <tooltip :helpText="'Project description'"></tooltip>
          </label>
          <textarea
            id="description"
            class="modal-text-area"
            rows="4"
            cols="50"
            v-model="project.description"
          ></textarea>
          <h2 class="modal-subtitle">Associated Program</h2>
          <error
            :errors="errors.project"
            :message="['Program is required']"
            :field="'programs'"
          >
            <label class="modal-label"
              >Associated Program <span class="req-asterik">*</span></label
            >
            <select v-model="project.programs" class="modal-text">
              <option
                v-for="program in programs"
                :value="program.id"
                :key="program.id"
              >
                {{ program.name }}
              </option>
            </select>
          </error>
          <h2 class="modal-subtitle">Associated Organizations</h2>
          <div v-for="(org, i) in project.organizations" :value="i" :key="i">
            <div class="box-three-quarters auto-box">
              <error
                :errors="errors.project"
                :message="['Organization is required']"
                :field="'organizations'"
              >
                <AutocompleteSearch
                  :search="org.name"
                  @selected="
                    (org.organization_id = $event.id), (org.name = $event.name)
                  "
                >
                </AutocompleteSearch>
              </error>
            </div>
            <div class="box-eigth">
              <error
                :errors="errors.project"
                :message="['Organization relationship is required']"
                :field="'organizations'"
              >
                <select v-model="org.relationship">
                  <option value="CLIE">
                    Client
                  </option>
                  <option value="FUND">
                    Funder
                  </option>
                  <option value="PTNR">
                    Partner
                  </option>
                </select>
              </error>
            </div>
            <div class="box-eigth remove-frame">
              <button
                class="remove-button"
                @click="project.organizations.splice(i, 1)"
              >
                <img src="../assets/icons/remove.svg" />
              </button>
            </div>
          </div>
          <button class="add-button" @click="project.organizations.push({})">
            Add Organization +
          </button>
          <h2 class="modal-subtitle" v-if="project.project_type === 'DLV'">
            Facilitation Level
          </h2>
          <error
            :errors="errors.project"
            :message="errors.project.facilitation_level"
            :field="'facilitation_level'"
          >
            <label class="modal-label" v-if="project.project_type === 'DLV'"
              >Delivery Project Facilitation Level
              <span class="req-asterik">*</span></label
            >
            <select
              class="modal-select"
              v-model="project.facilitation_level"
              v-if="project.project_type === 'DLV'"
            >
              <option value="FCL">
                Facilitated
              </option>
              <option value="SVG">
                Self-guided
              </option>
              <option value="FTS">
                Facilitated to self-guided
              </option>
            </select>
          </error>
          <h2 class="modal-subtitle" v-if="project.project_type === 'PRD'">
            Development Stage
          </h2>
          <error
            :errors="errors.project"
            :message="errors.project.development_stage"
            :field="'development_stage'"
          >
            <label class="modal-label" v-if="project.project_type === 'PRD'"
              >Product Development Stage
              <span class="req-asterik">*</span></label
            >
            <select
              class="modal-select"
              v-model="project.development_stage"
              v-if="project.project_type === 'PRD'"
            >
              <option value="DVP">
                Developing
              </option>
              <option value="IMP">
                Implementing
              </option>
              <option value="RVS">
                Revising
              </option>
            </select>
          </error>
          <div class="modal-button-box">
            <button class="button submit-modal" @click="createProject">
              Add Project
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Sidebar / Top bar html -->
    <div
      class="sidebar"
      :class="{ activeSidebar: showSidebar }"
      v-if="showSidebar"
    >
      <div class="title">
        <a href="/" class="sidebar-logo">
          <p class="title-text">GEPI</p>
          <div class="sidebar-icon"></div>
        </a>
      </div>
      <!--
      <div class="dropdown side-button">
        <button class="dropbtn button add-new">
          <div class="button-title">Add New</div>
          <div class="button-icon">+</div>
        </button>
        <div class="dropdown-content">
          <a
            class="side-option"
            v-on:click="
              (showModal = true),
                (addAward = true),
                (addProgram = false),
                (addProject = false),
                openModal()
            "
            >Award</a
          >
          <a
            class="side-option"
            v-on:click="
              (showModal = true),
                (addProgram = true),
                (addAward = false),
                (addProject = false),
                openModal()
            "
            >Program</a
          >
          <a
            class="side-option last-option"
            v-on:click="
              (showModal = true),
                (addProject = true),
                (addAward = false),
                (addProgram = false),
                openModal()
            "
            >Project</a
          >
        </div>
      </div>-->
      <!--<button class="button add-new">Add New</button>-->
      <div class="sidebar-links">
        <a href="/" v-bind:class="{ activePage: $route.name == 'index' }"
          ><div class="sidebar-link-icon">
            <img src="../assets/icons/home.svg" />
          </div>
          <div class="sidebar-text">Home</div></a
        >
        <a
          href="/contacts/organizations/"
          v-bind:class="{ activePage: $route.name == 'organizations' }"
        >
          <div class="sidebar-link-icon">
            <img
              style="filter: grayscale(100%) brightness(0) invert(1);"
              src="../assets/icons/organizations.svg"
            />
          </div>
          <div class="sidebar-text">Organizations</div>
        </a>
        <a href="/contacts/organizations/?type=municipality"
          ><div class="sidebar-text indent">Municipalities</div></a
        >
        <a href="/contacts/organizations/?funders_and_partners=true"
          ><div class="sidebar-text indent">Partners & Funders</div></a
        >

        <a
          href="/programs/"
          v-bind:class="{ activePage: $route.name == 'programs' }"
          ><div class="sidebar-link-icon">
            <img src="../assets/icons/programs.svg" />
          </div>
          <div class="sidebar-text">Program Cohorts</div></a
        >
        <a href="/programs/?center=govex"
          ><div class="sidebar-text indent">GovEx Cohorts</div></a
        >
        <a href="/programs/?center=bcpi"
          ><div class="sidebar-text indent">BCPI Cohorts</div></a
        >
        <!-- <a href="/programs/?center=partner"
          ><div class="sidebar-text indent">Partner Cohorts</div></a
        >-->
        <a
          href="/projects/"
          v-bind:class="{ activePage: $route.name == 'projects' }"
          ><div class="sidebar-link-icon">
            <img src="../assets/icons/projects.svg" />
          </div>
          <div class="sidebar-text">Projects</div></a
        >
        <a href="/projects/?center=govex"
          ><div class="sidebar-text indent">GovEx Projects</div></a
        >
        <a href="/projects/?center=bcpi"
          ><div class="sidebar-text indent">BCPI Projects</div></a
        >
        <!--<a href="/projects/?center=partner"
          ><div class="sidebar-text indent">Partner Projects</div></a
        >-->
        <!-- <a
          href="/contacts/"
          v-bind:class="{ activePage: $route.name == 'contacts' }"
          ><div class="sidebar-link-icon">
            <img src="../assets/icons/contacts.svg" />
          </div>
          <div class="sidebar-text">Contacts</div></a
        >
        <a
          href="/contacts/organizations/"
          v-bind:class="{ activePage: $route.name == 'organizations' }"
          ><div class="sidebar-text indent">Organizations</div></a
        >
        <a
          href="/contacts/people/"
          v-bind:class="{ activePage: $route.name == 'people' }"
          ><div class="sidebar-text indent">People</div></a
        > -->
        <hr class="sidebar-hr" />
        <a id="resources-sidebar-link"
          ><div class="sidebar-link-icon">
            <img src="../assets/icons/resources.svg" />
          </div>
          <div class="sidebar-text">Resources</div></a
        >
        <!--<a href="https://www.govex.works/docs/index.html" target="_blank"><div class="sidebar-text indent">Policies</div></a>-->
        <a href="http://knowledge.gepi.io/" target="_blank"
          ><div class="sidebar-text indent">Knowledge Portal</div></a
        >
        <a
          href="https://docs.google.com/spreadsheets/d/1qPG9MAABSKogjtZ-k6qHcOzpxzez_FvovxQhkDg1sXM/edit#gid=0"
          target="_blank"
          ><div class="sidebar-text indent">Glossary</div></a
        >
        <!-- <div v-if="permissions && permissions.includes('Executives')"> -->
        <hr class="sidebar-hr" />
        <a
          href="/dashboard/"
          v-bind:class="{ activePage: $route.name == 'dashboard' }"
          ><v-icon
            class="mdi mdi-chart-box"
            size="30"
            color="var(--white)"
          ></v-icon>
          <div class="sidebar-text">Ops KPI Dashboard</div></a
        >
        <!-- </div> -->
      </div>
    </div>
    <div class="navbar">
      <div class="dropdown account-box navbar-outline">
        <button class="dropbtn">
          <!--<img class="user-image" src="../assets/images/circle-placeholder.png">-->
          <div class="user-name"><!--{{getProfile.username}} -->Account</div>
          <div class="icon-arrow"></div>
        </button>
        <div class="dropdown-content">
          <!--<a href="#">View Profile</a>-->
          <a href="#" v-on:click="logOut">Log Out</a>
        </div>
      </div>
      <a href="/notifications" class="alert navbar-outline"
        ><div class="icon-alert"></div
      ></a>
      <button class="help-button">
        <a
          href="https://form.asana.com/?k=FbRWF_piVAuPfJEQzJuAIw&amp;d=1198720002001006"
          target="_blank"
          class="icon-help"
        ></a>
      </button>
      <button
        class="sidebar-button"
        v-on:click="toggleButtonSidebar"
        v-if="showSidebarButton"
      >
        <a class="icon-menu"></a>
      </button>
      <!--
            <div class="search-container">
                <button class="search-button"><div class="icon-search"></div></button>
                <input type="text" class="search-bar" placeholder="Search..">
            </div>
            -->
    </div>
    <!--
    <div id="success-snackbar" :class="{ show: success }">
      <div class="success-icon"></div>
      Success!
    </div>
    -->
    <!-- Sidebar / Top bar html end -->
  </div>
</template>

<style>
/* Page CSS (Side and top bar) */
.side-option {
  background-color: var(--aqua);
  width: 178px;
  margin: 0px 20px;
}
.side-option:hover {
  background-color: var(--aqua-alt-1) !important;
}
.side-button .dropdown-content {
  background-color: transparent;
  top: 116px;
}
.side-button {
  margin-top: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}
.side-button .dropdown-content {
  width: 210px;
}
.submit-modal:hover {
  background-color: var(--aqua-alt-1) !important;
}
.button.add-new:hover {
  background-color: var(--aqua-alt-1) !important;
  border-radius: 6px 6px 0px 0px;
}
.last-option {
  border-radius: 0px 0px 6px 6px;
}
/* Modal */
.modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  min-width: 500px;
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  z-index: 1000;
}
.modal-content {
  background-color: #fefefe;
  color: var(--dark-grey);
  border-radius: 6px;
  margin: 0px auto 200px auto;
  padding: 20px;
  padding-bottom: 100px;
  border: 1px solid var(--very-light-grey);
  width: 80%;
}
.modal-text-area {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 6px;
  box-sizing: border-box;
  width: 95%;
  padding: 10px 20px 10px 10px;
  margin: 10px 0px 10px 0px;
  background: #fefefe;
  font-family: "roboto";
}

.modal-date {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 6px;
  box-sizing: border-box;
  max-width: 500px;
  width: 90%;
  padding: 10px 20px;
  margin: 10px 10px 10px 0px;
  background: #fefefe;
  font-family: "roboto";
  color: var(--gray);
}

.modal-title {
  text-align: center;
  color: var(--very-dark-grey);
  border-bottom: 2px solid var(--very-light-grey);
  padding-bottom: 20px;
  font-family: "roboto-bold";
}
.modal-label {
  color: var(--very-dark-grey);
  font-family: "roboto";
  margin-top: 40px;
}
.modal-check-label {
  font-family: "roboto";
  display: inline;
  margin-left: 10px;
  padding-bottom: 5px;
}
.modal-check {
  width: 20px;
  height: 20px;
}

.check-line {
  margin-top: 20px;
}

.modal-subtitle {
  color: var(--very-dark-grey);
  font-family: "roboto-bold";
}
.req-warning {
  text-align: center;
  font-family: "roboto";
  color: var(--grey);
}
.req-asterik {
  color: var(--red);
  margin-left: 5px;
}
.modal-text {
  border: 1px solid rgb(236, 236, 236);
  border-radius: 6px;
  box-sizing: border-box;
  max-width: 1015px;
  width: 90%;
  padding: 6px 20px;
  margin: 10px 10px 10px 0px;
  background: #fefefe;
}
.imodal-text:focus {
  outline: solid 1px #cfcfcf;
}
.modal-text::placeholder {
  color: rgb(163, 163, 163);
}
/* The Close Button */
.close {
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
.sidebar-logo:hover {
  background: unset;
}
.sidebar a.sidebar-logo {
  padding: 0px;
}
.sidebar {
  color: var(--white);
}
.navbar {
  overflow: hidden;
  background-color: var(--white);
  box-shadow: 0px 8px 16px 0px rgba(10, 0, 2, 5%);
  border-bottom: 2px #e0e0e0;
  font-family: "roboto";
  border-style: solid;
  border-color: rgb(240, 240, 240);
  border-width: 2px;
}
.navbar a {
  float: right;
  font-size: 16px;
  color: #5c5c5c;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}
.search-bar {
  float: right;
  width: 30%;
  padding: 10px 20px;
  margin: 4px 10px;
  border: 1px solid rgb(236, 236, 236);
  border-radius: 4px;
  box-sizing: border-box;
}
.navbar-outline {
  border-style: solid;
  border-color: rgb(240, 240, 240);
  border-width: 0px 0px 0px 2px;
}
.search-bar:focus {
  outline: solid 1px #cfcfcf;
}
.search-bar::placeholder {
  color: rgb(163, 163, 163);
}
.search-button {
  float: right;
  height: 37px;
  margin-top: 4px;
  border-color: #dfdfdf;
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  margin-right: 10px;
  background-color: rgb(248, 248, 248);
}
.search-button:hover {
  background-color: rgb(233, 233, 233);
}

.help-button {
  float: right;
  height: 37px;
  margin-top: 3px;
  border-color: #dfdfdf;
  border-radius: 6px;
  border: 0px;
  margin-right: 10px;
  background-color: var(--aqua);
}
.help-button:hov {
  cursor: pointer;
}
.sidebar-button {
  float: right;
  height: 37px;
  margin-top: 6px;
  border-color: #dfdfdf;
  border-radius: 6px;
  border: 0px;
  margin-right: 10px;
  background-color: var(--aqua);
  margin-bottom: 6px;
}
.sidebar-button:hov {
  cursor: pointer;
}
.dropdown {
  float: right;
  overflow: hidden;
}
.dropdown .dropbtn {
  font-size: 16px;
  border: none;
  outline: none;
  color: #5c5c5c;
  padding: 5px 16px;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  height: 35px;
}
.account-box .dropbtn {
  height: 45px;
}
.navbar a:hover,
.account-box:hover {
  background-color: rgb(216, 216, 216);
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}
.dropdown-content a {
  float: none;
  color: #5c5c5c;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}
a.alert {
  padding: 0px 10px;
}
.user-image {
  height: 35px;
  padding-right: 18px;
}
.user-name {
  padding-top: 9px;
}

.remove-frame {
  position: absolute;
  margin-top: 9px;
}

.dropbtn {
  display: flex;
}
.dropdown-content a:hover {
  background-color: #ddd;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.icon-arrow {
  height: 20px;
  width: 20px;
  margin: auto;
  padding-left: 24px;
  background-color: #5c5c5c;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/down-arrow.svg");
}
.icon-alert {
  height: 45px;
  width: 25px;
  padding-top: 20px;
  margin: auto;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/alert.svg");
  float: right;
}
.icon-search {
  height: 25px;
  width: 25px;
  padding-top: 10px;
  margin: auto;
  background-color: #a1a1a1;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/search.svg");
  float: right;
}
.icon-help {
  height: 0px;
  zoom: 1.2;
  padding: 10px 0px 0px 0px;
  margin: auto;
  background-color: var(--white);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/question-mark.svg");
  float: right;
}
.icon-menu {
  height: 0px;
  zoom: 1.2;
  padding: 10px 0px 0px 0px;
  margin: auto;
  background-color: var(--white);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/menu.svg");
  float: right;
}
.button {
  background-color: var(--aqua);
  border: none;
  color: var(--aqua);
  padding: 16px 50px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
  border-radius: 6px;
}
.button.add-new {
  background-color: var(--aqua);
  color: var(--white);
  margin: 0px 20px;
  width: 210px;
  font-family: "roboto";
}
.button.submit-modal {
  background-color: var(--aqua);
  color: var(--white);
  margin: 0px 20px;
  width: 210px;
  font-family: "roboto";
  float: right;
  box-shadow: 2px 2px 2px 0px rgba(97, 97, 97, 30%);
  margin-top: 10px;
}
.sidebar .title {
  height: 90px;
  margin: 0px;
  padding: 10px;
  background-color: #2e2e2e;
  display: flex;
}
.sidebar .title p {
  margin: 10px 10px 0px 25px;
  font-size: 45px;
  font-family: "arvo";
  color: var(--white);
  display: inline;
}
.sidebar-icon {
  height: 45px;
  width: 60px;
  margin: auto 0px;
  background-color: var(--aqua);
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-image: url("../assets/icons/logo.svg");
}
.sidebar-text {
  margin-left: 20px;
  font-size: 14px;
  padding-top: 4px;
}
.sidebar-text.indent {
  margin-left: 60px;
}
.sidebar-hr {
  width: 80%;
  margin: 8px 10% 8px 10%;
  color: #aaaaaa;
}
.sidebar {
  margin: 0;
  padding: 0;
  width: 250px;
  background-color: #3f3f3f;
  position: fixed;
  height: 100%;
  overflow: auto;
  box-shadow: 2px 2px 10px 0px rgb(61, 61, 61);
  z-index: 999;
}
.sidebar a {
  display: block;
  color: var(--white);
  padding: 5px 16px;
  text-decoration: none;
  font-family: "roboto";
  display: flex;
}
.sidebar a#resources-sidebar-link:hover {
  background: initial;
}
.sidebar-links {
  padding-top: 20px;
}
.sidebar-links a {
  margin: 2px 15px;
  border-radius: 10px;
}
.sidebar-links a.activePage {
  background: var(--dark-grey);
}

.sidebar a.active {
  background-color: var(--dark-grey);
  color: var(--white);
}
.sidebar-links a:hover:not(.active) {
  background-color: var(--dark-grey);
  color: var(--white);
}
.sidebar-button {
  display: none;
}

.modal-select {
  width: 90%;
}
.sub-label {
  color: var(--grey);
  font-size: 12px;
  margin-top: 4px;
}

.label-short {
  margin-top: 8px;
}

.label-med {
  margin-top: 30px;
}

.modal-org {
  width: 80%;
}

.modal-relationship {
  width: 100px;
}

/* Responsive formatting */
@media screen and (max-width: 750px) {
  .sidebar-button {
    display: unset;
  }

  .search-container {
    display: none;
  }
  .help-button {
    display: none;
  }
  .alert {
    display: none;
  }
  .account-box {
    display: none;
  }
  div.content {
    margin-left: 0px !important;
  }
  .activeSidebar {
    display: unset;
  }

  #success-snackbar {
    visibility: hidden;
    min-width: 250px;
    margin-left: -125px;
    background-color: rgba(92, 184, 92, 0.3);
    color: rgba(92, 184, 92);
    text-align: center;
    border-radius: 2px;
    padding: 16px;
    position: fixed;
    z-index: 1;
    left: 50%;
    bottom: 30px;
    font-size: 17px;
    font-family: "roboto-bold";
  }

  #success-snackbar.show {
    visibility: visible;
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }

  @-webkit-keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  .success-icon {
    height: 25px;
    width: 25px;
    display: inline-block;
    background-color: rgba(92, 184, 92);
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    mask-image: url("../assets/icons/success.svg");
  }
  .organization-icon {
    filter: grayscale(100%) brightness(0) invert(1);
  }
}
</style>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import Error from "../components/Error.vue";
import AutocompleteSearch from "../components/AutocompleteSearch.vue";
import Tooltip from "../components/Tooltip.vue";

export default {
  components: {
    Error,
    AutocompleteSearch,
    Tooltip,
  },
  data() {
    return {
      programSelected: "",
      showModal: false,
      addAward: false,
      addProject: false,
      addProgram: false,
      morePrograms: false,
      program: {},
      award: { organizations: [] },
      project: { organizations: [] },
      programs: [],
      organizations: [],
      awards: [],
      showSidebar: true,
      showSidebarButton: true,
      sidebarToggle: false,
      test: [],
      awardStages: {
        Exploratory: "Exploratory Conversations",
        Development: "Statement of Work and/or Budget in Development",
        Review: "Statement of Work and Budget Review by Dean's Office",
        Contract: "Dean's Office Issued Contract to Client",
        Signed: "Client Signs Contract",
        Payment: "Payment Received",
        Active: "Active",
        Extension: "No-cost extension",
        Completed: "Completed",
      },
      errors: {
        program: {},
        project: {},
        award: {},
      },
      success: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    ...mapGetters(["permissions"]), // user mapGetters method to use an array of all your store getters
    // s
  },

  created() {
    // this.getPrograms();
    // this.getAwards();
    this.setSidebar();
  },
  mounted() {
    // Register an event listener when the Vue component is ready
    window.addEventListener("resize", this.setSidebar);
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    logOut: function() {
      this.$store.dispatch("AUTH_LOGOUT", {});
      this.$router.push("/login");
    },

    createAward: function() {
      this.$api
        .post("awards/", this.award)
        .then((response) => {
          this.success = response.status === 201;
          this.showModal = false;
        })
        .catch((error) => {
          this.errors.award = error.response.data;
          console.log(error.response.data);
        });
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },

    createProgram: function() {
      this.$api
        .post("programs/", this.program)
        .then((response) => {
          this.success = response.status === 201;
          this.showModal = false;
        })
        .catch((error) => {
          this.errors.program = error.response.data;
          console.log(error.response.data);
        });
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },

    createProject: function() {
      this.$api
        .post("projects/", this.project)
        .then((response) => {
          this.successs = response.status === 201;
          this.showModal = false;
        })
        .catch((error) => {
          this.errors.project = error.response.data;
          console.log(error.response.data);
        });
      setTimeout(() => {
        this.success = false;
      }, 3000);
    },

    getAwards: async function() {
      let url = "awards?ordering=name";
      while (url) {
        let response = await this.$api.get(url);
        await this.awards.push.apply(this.awards, response.data.results);
        url = response.data.next;
      }
    },

    getPrograms: async function() {
      let url = "programs?ordering=name";
      while (url) {
        let response = await this.$api.get(url);
        await this.programs.push.apply(this.programs, response.data.results);
        url = response.data.next;
      }
    },

    setSidebar: function() {
      if (window.innerWidth > 750) {
        this.showSidebar = true;
        this.sidebarToggle = false;
      } else {
        if (this.sidebarToggle) {
          this.showSidebar = true;
        } else {
          this.showSidebar = false;
        }
      }
    },
    toggleButtonSidebar: function() {
      this.sidebarToggle = !this.sidebarToggle;
      if (this.sidebarToggle) {
        this.showSidebar = true;
      } else {
        this.showSidebar = false;
      }
    },
    openModal: function() {
      // open modal
      this.showProjectModal = !this.showProjectModal;
      // clear out data
      (this.award = {
        organizations: [{ name: "", organization_id: "", relationship: "" }],
      }),
        (this.project = {
          organizations: [{ name: "", organization_id: "", relationship: "" }],
        }),
        (this.program = {});
      // clear out errors
      for (const [key, value] of Object.entries(this.errors)) {
        this.errors[key] = {};
      }
    },
  },
};
</script>
